/* You can add global styles to this file, and also import other style files */

@keyframes error-animation {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 15px;
  }
}

body {
  overflow-y: hidden;
  overflow-x: hidden;
}

main {
  transition: width, margin 330ms ease;
}

.icon-tabler {
  stroke-width: 2;
}

.pinned {
  left: 0;
  position: sticky;
  background-color: inherit;
  box-shadow: inset -1px 0px 0px 0px lightgrey;
}

tbody tr:nth-child(even) .pinned {
  background-color: white;
}

tbody tr:hover .pinned {
  background-color: #f1f3f5;
}

th {
  background-color: white !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
